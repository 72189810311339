<template>
  <Article
    :header-image="sdCover"
    header-text="ux design"
    section-type-name="ux design"
    title="Sentinel Digital"
  >
    <template v-slot:body>
      <div class="intro">
        <Role :roles="sentinelContent.details.role" />
        <Section :body="sdDesc" :title="sdTitle" />
      </div>
      <Section :body="targetBody" :title="targetTitle" />
      <SubtitleList :list="businessList" subtitle="Business Goals" />
      <Section
        :body="personasBody"
        :title="personasTitle"
        super-title="UX Process"
      />

      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :exp-size="80"
            :src="sandra"
            alt="Super-user sandra"
            cap="Super-user Sandra"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :exp-size="80"
            :src="david"
            alt="Maybe-digital David"
            cap="Maybe-digital David"
          />
        </template>
        <template v-slot:3>
          <StagedFigureImage
            :exp-size="80"
            :src="meg"
            alt="Media-savvy Meg"
            cap="Media-savvy Meg"
          />
        </template>
      </Grid>
      <Section :body="audienceBody" :title="audienceTitle" />

      <StagedFigureImage
        :exp-size="90"
        :src="storyMap"
        alt="Sentinel reader story map"
        cap="Sentinel reader story map"
      />
      <SubtitleList :body="storyMapSummary" :list="storyMapList" />

      <Section
        :body="readingBody"
        :super-title="cleanUITitle"
        :title="readingTitle"
      />

      <StagedFigureImage
        :exp-size="90"
        :src="articlePage"
        alt="Article page on desktop"
        cap="Article page on desktop"
      />

      <InteractionVideo :video="articleVideo" />

      <Section :body="simpleBody" :title="simpleTitle" />

      <StagedFigureImage
        :exp-size="50"
        :size="40"
        :src="card"
        alt="Card view containing information about an issue"
        cap="Card view containing information about an issue"
      />

      <Section :body="colorSchemeBody" :title="colorSchemeTitle" />

      <StagedFigureImage
        :src="audioLandingPage"
        alt="Audio landing page"
        cap="Card view containing information about an issue"
      />

      <Section :body="contentSizeBody" :title="contentSizeTitle" />

      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :exp-size="43"
            :src="toc"
            alt="Table of contents page. Each card represents a piece of content from the issue."
            cap="Table of contents page. Each card represents a piece of content from the issue."
          />
          <StagedFigureImage
            :src="exclusives"
            alt="Web exclusives page. Each card represents a piece of web-only content."
            cap="Web exclusives page. Each card represents a piece of web-only content."
          />
        </template>
      </Grid>

      <Section :body="mobileBody" :title="mobileTitle" />

      <Grid>
        <template v-slot:1>
          <StagedFigureImage
            :src="tocMobile"
            alt="Home page on mobile"
            cap="Home page on mobile"
          />
        </template>
        <template v-slot:2>
          <StagedFigureImage
            :src="exclusivesMobile"
            alt="Web exclusives on mobile"
            cap="Web exclusives on mobile"
          />
        </template>
        <template v-slot:3>
          <StagedFigureImage
            :src="audioMobile"
            alt="Audio modal console on mobile"
            cap="Audio modal console on mobile"
          />
        </template>
      </Grid>

      <Section :body="verificationBody" :title="verificationTitle" />

      <BackToTop />
    </template>
  </Article>
</template>

<script>
import SectionHeader from "../../../components/section_header/SectionHeader.vue";
import BackToTop from "@/components/controls/button/BackToTop.vue";
import { colors } from "@/core/colors.js";
import { written } from "@/core/data/written_data.ts";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";
import SubtitleList from "@/components/info/desktop/Subtitle_List.vue";
import BodyBullets from "@/components/info/desktop/Body_Bullets.vue";
import Figure from "@/components/info/desktop/Figure.vue";
import Role from "@/components/info/desktop/Role.vue";
import VideoPlayer from "@/components/info/VideoPlayer.vue";
import Article from "@/components/info/desktop/Article.vue";
import sdCover from "@/assets/images/SD_iso.webp";
import Section from "@/components/info/desktop/Section.vue";
import { uxProjects } from "@/core/data/projects";
import Row from "@/components/info/desktop/figure/row.vue";
import sandra from "@/assets/images/personas/personas_sandra.webp";
import david from "@/assets/images/personas/personas_david.webp";
import meg from "@/assets/images/personas/personas_meg.webp";
import StagedFigureImage from "@/components/info/desktop/figure/staged_figure.vue";
import Column from "@/layouts/Column.vue";
import Grid from "@/components/info/desktop/figure/grid.vue";
import storyMap from "@/assets/images/storymap.webp";
import articlePage from "@/assets/images/article_page.webp";
import articleVideo from "@/assets/videos/article.mp4";
import InteractionVideo from "@/components/info/InteractionVideo.vue";
import card from "@/assets/images/vertical_thumbnail_header_card.webp";
import audioLandingPage from "@/assets/images/audio_landing_page.webp";
import toc from "@/assets/images/toc.webp";
import tocMobile from "@/assets/images/toc_mobile.webp";
import exclusives from "@/assets/images/web_exclusives.webp";
import exclusivesMobile from "@/assets/images/web_exclusives_mobile.webp";
import audioMobile from "@/assets/images/audio_modal.webp";

export default {
  name: "SentinelDigital",
  components: {
    InteractionVideo,
    Grid,
    Column,
    StagedFigureImage,
    Row,
    Section,
    Article,
    SectionHeader,
    BackToTop,
    SubtitleBody,
    SubtitleList,
    Role,
    Figure,
    BodyBullets,
    VideoPlayer,
  },
  data() {
    return {
      label: "explore",
      hasHovered: false,
    };
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    sentinelContent: function () {
      return written.projects.sentinel;
    },
    sdCover: () => sdCover,
    david: () => david,
    sandra: () => sandra,
    meg: () => meg,
    storyMap: () => storyMap,
    articlePage: () => articlePage,
    card: () => card,
    audioLandingPage: () => audioLandingPage,
    articleVideo: () => articleVideo,
    exclusives: () => exclusives,
    exclusivesMobile: () => exclusivesMobile,
    audioMobile: () => audioMobile,
    toc: () => toc,
    tocMobile: () => tocMobile,
    sdDesc: () => uxProjects.sentinel.details.description.description,
    sdTitle: () => uxProjects.sentinel.details.description.title,
    targetTitle: () => uxProjects.sentinel.details.targetAudience.title,
    targetBody: () => uxProjects.sentinel.details.targetAudience.description,
    businessList: () => uxProjects.sentinel.details.businessGoals,
    personasTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[0].title,
    personasBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[0].description,
    audienceTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[1].title,
    audienceBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[1].description,
    storyMapList: () =>
      uxProjects.sentinel.details.uxProcess.processes[2].listDetails[0].list,
    storyMapSummary: () =>
      uxProjects.sentinel.details.uxProcess.processes[2].listDetails[0]
        .description,
    cleanUITitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].title,
    readingBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[0]
        .description,
    readingTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[0].title,
    simpleTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[1].title,
    simpleBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[1]
        .description,
    colorSchemeTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[2].title,
    colorSchemeBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[3].listDetails[2]
        .description,
    contentSizeTitle: () =>
      uxProjects.sentinel.details.uxProcess.processes[4].title,
    contentSizeBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[4].description,
    mobileBody: () =>
      uxProjects.sentinel.details.uxProcess.processes[5].description,
    mobileTitle: () => uxProjects.sentinel.details.uxProcess.processes[5].title,
    verificationTitle: () => uxProjects.sentinel.details.verification.title,
    verificationBody: () =>
      uxProjects.sentinel.details.verification.description,
  },
  methods: {
    renderHover: function () {
      this.hasHovered = true;
    },
    renderNormal: function () {
      this.hasHovered = false;
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.intro {
  display: grid;
  gap: 2rem;
}

.sd-container {
  width: 100vw;
  margin: 3rem 0 45% 0;
  display: flex;
  flex-flow: column;
}

.header-image {
  z-index: 0;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
}

.persona-image {
  width: 14.187rem !important;
  position: relative !important;
  height: 17rem;
}

.figures {
}
</style>
