<template>
  <div :style="{'grid-gap': `${margin}`}">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    margin: {
      type: String,
      default: "0",
    },
  },
};
</script>

<style scoped>
div {
  display: grid;
  grid-template-columns: auto;
}
</style>